import React from "react";
import BasePage from "./Components/Pages/Base/BasePage";
import UserContext from "./Components/Contexts/UserContext";
import AdContext from "./Components/Contexts/AdContext";
import VideoContext from "./Components/Contexts/VideoContext";
import "./Styles/Funker530.scss";
import ErrorBoundary from "./Components/Generic/ErrorBoundary";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";

function Funker530() {
  ReactGA.initialize("G-0RHX2WKB1Q");
  ReactGA.gtag("config", "G-0RHX2WKB1Q");

  return (
    <ErrorBoundary
      customError="There was an error loading Funker530.com. The site is currently undergoing maintenance, please try again in a few moments."
      className="mainError"
    >
      <HelmetProvider>
        <UserContext>
          <AdContext>
            <VideoContext>
              <BasePage />
            </VideoContext>
          </AdContext>
        </UserContext>
      </HelmetProvider>
    </ErrorBoundary>
  );
}

export default Funker530;
