import React, { useEffect, useState, useRef } from "react";
import { UseAdContext } from "../Contexts/AdContext";
import { UseUserContext } from "../Contexts/UserContext";
import Median from "median-js-bridge";
import ReactGA from "react-ga4";

function Ad(props) {
  const { GetAds } = UseAdContext();
  const { user, loggingIn } = UseUserContext();
  const [ad, SetAd] = useState([]);
  const [totalAdLoads, SetTotalAdLoads] = useState(0);
  const [inView, SetInView] = useState(false);

  const adRef = useRef(null);

  const adTypes = {
    banner: 2,
    box: 3,
  };

  async function LoadAd(force = false) {
    if (!AdLoadEnabled(force)) {
      return;
    }
    SetAd([]);
    let newAd = await GetAds({
      amount: props.amount,
      typeId: adTypes[props.type],
    });
    if (newAd?.data instanceof Array) {
      SetAd(newAd.data[0]);
      SetTotalAdLoads(totalAdLoads + 1);
    }
  }

  function HandleClick(e, linkUrl) {
    e.preventDefault();
    if (linkUrl == null) {
      return;
    }
    if (Median.isNativeApp()) {
      Median.window.open(linkUrl, "external");
      return;
    }
    window.open(linkUrl, "_blank");
  }

  function AdLoadEnabled(force = false) {
    return (
      !loggingIn &&
      !user?.isPremium &&
      adRef.current != null &&
      document.fullscreenElement == null &&
      ((inView && totalAdLoads <= 5) || force)
    );
  }

  useEffect(() => {
    let interval = setInterval(() => {
      if (AdLoadEnabled()) {
        LoadAd();
      }
    }, 60000);

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAdLoads]);

  useEffect(() => {
    if (totalAdLoads > 0) {
      return;
    }
    SetAd({});
    LoadAd();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggingIn, inView]);

  useEffect(() => {
    SetTotalAdLoads(0);
    LoadAd();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          SetInView(entry.isIntersecting);
          if (entry.isIntersecting) {
            LoadAd();
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(adRef.current);

    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adRef]);

  useEffect(() => {
    if (adRef.current != null && totalAdLoads > 0) {
      LoadAd(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, adRef]);

  useEffect(() => {
    if (
      ad == null ||
      ad?.vendor?.googleAnalyticsTag == null ||
      ad?.vendor?.googleAnalyticsTag === ""
    ) {
      return;
    }
    ReactGA.gtag("event", "page_view", {
      event_category: "Ad View",
      event_label: ad.adName,
      send_to: ad.vendor.googleAnalyticsTag,
    });
  }, [ad]);

  return (
    <a
      href={ad?.fullLinkUrl}
      className={"ad " + props.type}
      onClick={(e) => {
        HandleClick(e, ad?.fullLinkUrl);
      }}
      ref={adRef}
    >
      {ad?.imagePath != null && !user?.isPremium && (
        <img
          src={ad.imagePath}
          alt={"Advertisement " + props.type}
          loading="lazy"
        />
      )}
    </a>
  );
}

export default Ad;
